// Listing of pages for URL fabrication
const configureItemPage = '/site/pages/ConfigureItem.aspx';

// Reconstitute the application root URL for the Web Configurator.
// Example return:  http://localhost:5000/experlogix
export const getExperlogixRootUrl = (anyExperlogixUrl: string) => {
    const url = new URL(anyExperlogixUrl);

    // Ignoring the first character, which is guaranteed to be a slash, find the first
    // slash which terminates the first path element (if present).
    var firstSlashPosition = url.pathname.indexOf('/', 1);

    // Preserve the path up to the first path element, if discovered.
    const path = firstSlashPosition === -1 ? url.pathname : url.pathname.slice(0, firstSlashPosition);

    return `${url.protocol}//${url.hostname}${path}`;
};

export const getExperlogixConfigureItemUrl = (anyExperlogixUrl: string) => `${getExperlogixRootUrl(anyExperlogixUrl)}${configureItemPage}`;

export const assembleUrlParams = (...urlParams: { key: string | undefined; value: string }[]) =>
    urlParams
        .map(p => (p.key !== undefined ? `${encodeURIComponent(p.key)}=${encodeURIComponent(p.value)}` : encodeURIComponent(p.value)))
        .join('&');
